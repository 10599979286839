<template>
  <main id="app"
        class="page-wrapper"
        @click="setInteraction($event)">



    <!-- <div id="fixedPlusButton" style="font-size:22px;cursor:pointer;border:1px solid #eee;box-shadow:0 1px 11px 0 rgba(0,0,0,.1);display:flex;justify-content: center;align-items: center;  z-index:99;position:fixed;bottom: 20px;left:20px;height:65px;width:65px;border-radius:100%;color:white;background-color:#ab8ce4;">
      <i class="fa fa-plus"></i>
    </div> -->






    <div id="loadingOverlay"
         v-show="isLoading">
      <i class="fa-solid fa-gear fa-spin"></i>
    </div>






    <HeaderNav v-if="$router.currentRoute.name != 'login'"
               @openQuickCalendar="$refs.quickCalendar.show()"
               @toggleMenu="hideSidebar = !hideSidebar"></HeaderNav>

    <div class=''
         :class='{ "sidebar-close": hideSidebar, "page-body-wrapper": $store.state.isLogged }'>

      <SideMenu ref="sideMenu"
                @hideSidebarIfMobile="hideSidebar = $globals.screenIsMobile"
                v-if="$store.state.isLogged"></SideMenu>



      <!-- change the min-height of page-body to be by class -->

      <div class="page-body">
        <div style="padding-bottom:50px;min-height:100vh"
             class="togglePaddingBottom50">
          <transition name="slide-fade"
                      mode="out-in"
                      appear>
            <router-view />
          </transition>
        </div>






        <!-- fugly but works -->
        <v-style v-if="$router.currentRoute.name == 'login'">

          @media (min-width:989px){
          footer.dark-footer{
          position: absolute !important;
          }
          .togglePaddingBottom50{
          padding-bottom:0 !important;
          }
          }

        </v-style>


        <footer class="dark-footer">
          <div class="container-fluid">
            <div class="row">
              <div class="col-6 footer-copyright">
                <p class="mb-0">



                  2024

                  <br class="hideForDesktop">
                  &#169;

                  PlanUp


                  <br class="hideForDesktop">

                  מבית


                  <a href="index.html"
                     style="color:#0E9A86">mit4mit</a>


                </p>
              </div>
              <div class="col-6">
                <ul class="footer-links">
                  <li><router-link to="/terms">תנאי שימוש</router-link></li>
                  <br class="hideForDesktop">
                  <li><router-link to="/privacy">פרטיות</router-link></li>
                  <br class="hideForDesktop">
                  <li><router-link to="/accessibility">נגישות</router-link></li>
                </ul>
              </div>
            </div>
          </div>
        </footer>

      </div>
    </div>

    <ContactModal v-if="$store.state.isLogged" />

    <TaskModal v-if="$store.state.isLogged"
               ref="taskModal" />




    <Modal xlarge="true"
           title="יומן"
           period="day"
           :hideFooter="true"
           ref="quickCalendar">

      <template v-slot:body>






        <div class="planupCalendarWrapper horizontalCustomerScrollbar">
          <PlanupCalendar :hideHeaderButtons="false"
                          v-if="$store.state.isLogged"
                          height="770px"></PlanupCalendar>
        </div>



      </template>



    </Modal>




    <CommentModal ref="commentModal" />





    <Modal ref="globalPdfIframeModal"
           class="globalPdfIframeModal"
           :title="globalPdfIframeModalTitle"
           :xlarge="true"
           :hideFooter="true">

      <template v-slot:body>
        <iframe :src="globalPdfIframeModalSrc"
                style="width:100%;border:none;height:70vh"
                v-if="globalPdfIframeModalSrc">

        </iframe>
      </template>
    </Modal>


    <Modal ref="targetForNewEntityModal"
           :title="$t('targetForNewEntityModalTitleFor_' + chosenEntityToCreateInPopup)"
           :hideFooter="true">

      <template v-slot:body>

        <div style="display:flex;justify-content: center;align-items:center;">

          <div v-if="$route.name == 'CustomerPage'">

            <div v-show="chosenEntityToCreateInPopup == 'meeting'"
                 data-bs-dismiss="modal"
                 class="card popupOptionBlock"
                 :class="chosenEntityToCreateInPopup"
                 @click="$root.$emit('addMeetingToThisCustomer')">
              <div>
                <i class="fa-solid fa-user-pen"
                   style="transform:scale(0.75)"></i>
                <br>
                לקוח הזה
              </div>
            </div>


            <div v-show="chosenEntityToCreateInPopup == 'event'"
                 data-bs-dismiss="modal"
                 class="card popupOptionBlock"
                 :class="chosenEntityToCreateInPopup"
                 @click="$root.$emit('addEventToThisCustomer')">
              <div>
                <i class="fa-solid fa-user-pen"
                   style="transform:scale(0.75)"></i>
                <br>
                לקוח הזה
              </div>
            </div>

            <div v-show="chosenEntityToCreateInPopup == 'taxDocument'"
                 data-bs-dismiss="modal"
                 class="card popupOptionBlock"
                 :class="chosenEntityToCreateInPopup"
                 @click="$root.$emit('addTaxDocumentToThisCustomer', chosenEntityToCreateInPopup_extraParams)">
              <div>
                <i class="fa-solid fa-user-pen"
                   style="transform:scale(0.75)"></i>
                <br>
                לקוח הזה
              </div>
            </div>


          </div>












          <div v-show="chosenEntityToCreateInPopup == 'event'"
               data-bs-dismiss="modal"
               class="card popupOptionBlock"
               :class="chosenEntityToCreateInPopup"
               @click="$handlers.customerHandler.goToAddCustomerAddEvent()">
            <div>
              <i class="fa-solid fa-user-plus"
                 style="transform:scale(0.75)"></i>
              <br>
              {{ $t('newCustomer') }}
            </div>
          </div>

          <div v-show="chosenEntityToCreateInPopup == 'event'"
               data-bs-dismiss="modal"
               class="card popupOptionBlock"
               :class="chosenEntityToCreateInPopup"
               @click="$root.$emit('findCustomer', $handlers.eventHandler.gotoAddNewEventToCustomerFromSearchResult)">
            <div>
              <i class="fa-solid fa-user-group"
                 style="transform:scale(0.75)"></i>
              <br>
              {{ $t('existingCustomer') }}
            </div>
          </div>







          <!-- --- -->






          <div v-show="chosenEntityToCreateInPopup == 'taxDocument'"
               data-bs-dismiss="modal"
               class="card popupOptionBlock"
               :class="chosenEntityToCreateInPopup"
               @click="$router.push(`/createTaxDocument?type=${chosenEntityToCreateInPopup_extraParams.taxDocumentType.value}`)">
            <div>
              <i class="fa-solid fa-user-plus"
                 style="transform:scale(0.75)"></i>
              <br>
              {{ $t('newCustomer') }}
            </div>
          </div>

          <div v-show="chosenEntityToCreateInPopup == 'taxDocument'"
               data-bs-dismiss="modal"
               class="card popupOptionBlock"
               :class="chosenEntityToCreateInPopup"
               @click="$root.$emit('findCustomer', $handlers.taxDocumentHandler.createGoToFunctionForFindCustomer(chosenEntityToCreateInPopup_extraParams.taxDocumentType))">
            <div>
              <i class="fa-solid fa-user-group"
                 style="transform:scale(0.75)"></i>
              <br>
              {{ $t('existingCustomer') }}
            </div>
          </div>



          <div v-show="chosenEntityToCreateInPopup == 'meeting'"
               data-bs-dismiss="modal"
               class="card popupOptionBlock"
               :class="chosenEntityToCreateInPopup"
               @click="openMeetingWithoutCustomerModal()">
            <div>
              <i class="fa-solid fa-user-slash"
                 style="transform:scale(0.75)"></i>
              <br>
              {{ $t('withoutCustomer') }}
            </div>
          </div>



          <div v-show="chosenEntityToCreateInPopup == 'meeting'"
               data-bs-dismiss="modal"
               class="card popupOptionBlock"
               :class="chosenEntityToCreateInPopup"
               @click="$handlers.customerHandler.goToAddCustomerOnlyMeeting();">
            <div>
              <i class="fa-solid fa-user-plus"
                 style="transform:scale(0.75)"></i>
              <br>
              {{ $t('newCustomer') }}
            </div>
          </div>

          <div v-show="chosenEntityToCreateInPopup == 'meeting'"
               data-bs-dismiss="modal"
               class="card popupOptionBlock"
               :class="chosenEntityToCreateInPopup"
               @click="$root.$emit('findCustomer', $handlers.meetingHandler.gotoAddNewMeetingFromSearch)">
            <div>
              <i class="fa-solid fa-user-group"
                 style="transform:scale(0.75)"></i>
              <br>
              {{ $t('existingCustomer') }}
            </div>
          </div>






          <div v-show="chosenEntityToCreateInPopup == 'task'"
               data-bs-dismiss="modal"
               class="card popupOptionBlock"
               :class="chosenEntityToCreateInPopup"
               @click="$root.$emit('openTaskModal')">
            <div>
              <i class="fa-solid fa-user-slash"
                 style="transform:scale(0.75)"></i>
              <br>
              {{ $t('withoutCustomer') }}
            </div>
          </div>



          <div v-show="chosenEntityToCreateInPopup == 'task'"
               data-bs-dismiss="modal"
               class="card popupOptionBlock"
               :class="chosenEntityToCreateInPopup"
               @click="$handlers.customerHandler.goToAddCustomerOnlyTask();">
            <div>
              <i class="fa-solid fa-user-plus"
                 style="transform:scale(0.75)"></i>
              <br>
              {{ $t('newCustomer') }}
            </div>
          </div>

          <div v-show="chosenEntityToCreateInPopup == 'task'"
               data-bs-dismiss="modal"
               class="card popupOptionBlock"
               :class="chosenEntityToCreateInPopup"
               @click="$root.$emit('findCustomer', $handlers.tasksHandler.gotoAddNewTaskFromSearch)">
            <div>
              <i class="fa-solid fa-user-group"
                 style="transform:scale(0.75)"></i>
              <br>
              {{ $t('existingCustomer') }}
            </div>
          </div>






        </div>
      </template>
    </Modal>

    <notifications position="top center"
                   @click.native="clickOnNotification($event)"
                   class="planupnotification">

    </notifications>

    <!-- THIS MODAL SHOULD BE LAST TO BE ABOVE ALL MODALS -->
    <FindCustomerModal v-if="$store.state.isLogged"
                       ref="findCustomerModal"></FindCustomerModal>


    <QuickMeetingModal v-if="meetingWithoutCustomer"
                       ref="meetingWithoutCustomerModal"
                       @savedMeeting="meetingWithoutCustomer = null"
                       :meetingToEdit="meetingWithoutCustomer"></QuickMeetingModal>



    <Modal :hideFooter="true"
           ref="globalChatBox"
           class="globalChatBoxModal">

      <template v-slot:body>
        <PlanupMultiMessagesChat ref="globalChatBoxInner"
                                 :msgGroup="chatMsgGroup"></PlanupMultiMessagesChat>
      </template>


    </Modal>



    <SummonModal ref="summonModal"></SummonModal>




  </main>
</template>

<script>

import QuickMeetingModal from './components/Meeting/QuickMeetingModal.vue';
import CommentModal from './components/CommentModal/CommentModal.vue';
import FindCustomerModal from './components/Customers/FindCustomerModal.vue';
import ContactModal from './components/Modals/ContactModal';
import SideMenu from './components/Common/SideMenu.vue'
import HeaderNav from './components/Common/HeaderNav.vue'
import Login from './components/Login.vue';
import axiosV2 from './core/http/axios_api_v2';
import TaskModal from './components/Tasks/TaskModal.vue';
import PlanupCalendar from './components/Calendar/PlanupCalendar.vue';
import PlanupMultiMessagesChat from './components/PlanupMultiMessages/PlanupMultiMessagesChat.vue';
import SummonModal from './components/SummonModal/SummonModal.vue';

export default {
  name: 'App',
  data() {
    return {
      meetingWithoutCustomer: null,
      chatMsgGroup: '',
      hideSidebar: false,
      chosenEntityToCreateInPopup: null,
      chosenEntityToCreateInPopup_extraParams: null,
      globalPdfIframeModalSrc: null,
      globalPdfIframeModalTitle: null,
      isLoading: false,
    }
  },

  beforeMount() {
    window.seatupApp = this;
    window.planup = this; //yonso add
    window.axios = axiosV2;




    //to make clones of an object, so that we can edit something without disturbing the original
    window.deepCopy = function (o) { if (!o) return null; return JSON.parse(JSON.stringify(o)) };
    window.emptyObject = o => !Object.keys(o).length;
    window.shallowCopy = function (o) { let o2 = {}; Object.assign(o2, o); return o2; }
    window.setNoon = function (d) { d && d.setHours && d.setHours(12) }




    window.removeFromArray = (removeMe, arr, replacement) => {

      let index = arr.findIndex(e => e == removeMe);
      arr.splice(index, 1, replacement);


    }




    window.componentPath = (c) => {

      if (c.$options.name == 'App')
        return 'App';

      return componentPath(c.$parent) + '.' + c.$options.name;
    }


    //usually to bring our changes to the original object, we update each key 
    // window.keyCopy = (source,destination)=>{

    //   if (!destination)
    //     return;

    //   let keys = Object.keys(source);
    //         for (let i=0; i<keys.length; i++){
    //           destination[keys[i]] = source[keys[i]];
    //         }
    // }



    //not the prettiest injection, but very comfortable to use
    //also - i now understand that splice and push are vue functions, so i really mostly won't need this
    //i would never really REPLACE an item in an array eg. list[4] = somethingElse, i would edit list[4].someField = somethingElse
    // this.$store.updateList = (listName) => planup.$store.state[listName] = deepCopy(planup.$store.state[listName])

    this.$store.commit('setAccessToken', null);


  },



  watch: {



    // i think this is the init of planupCustomerStatuses from server to quickSuperEdit
    '$store.state.planupCustomerStatuses': function (newValue) {


      if (!newValue.length)
        return;

      //if already init
      if (typeof newValue[0]?.type?.value != 'undefined')
        return;


      this.$store.state.planupCustomerStatuses = newValue.map(status => {
        status.type = planup.$handlers.customerHandler.planupCustomerStatusTypeOptions.find(x => x.value == status.type || (!x.value && !status.type))
        return status;
      });
    },


  },





  methods: {

    openMeetingWithoutCustomerModal(date, meeting, time) {

      this.meetingWithoutCustomer = meeting ? meeting : this.$handlers.meetingHandler.generateLocalEmptyMeeting({}, date, time);
      this.$nextTick(() => this.$refs.meetingWithoutCustomerModal.show())

    },


    showQuickCalendar(displayType) {

      displayType == 'day' ? this.$refs.quickCalendarActualCalendar.setDayView() : this.$refs.quickCalendarActualCalendar.setMonthView();
      this.$refs.quickCalendar.show();

    },


    clickOnNotification(event) {

      if (event.target.classList.contains('whatsapp')) {



        let contactId = Array.from(event.target.classList).find(c => c.slice(0, 7) == 'contact').slice(7);
        // let customerId = Array.from(event.target.classList).find(c => c.slice(0, 8) == 'customer').slice(8);


        this.$root.$emit('globalChatBox', `whatsapp_contact_${contactId}`)


      }

    },

    openGlobalChatBox(msgGroup) {

      this.chatMsgGroup = msgGroup;
      this.$refs.globalChatBox.show();
      setTimeout(() => {
        this.$refs.globalChatBoxInner.scrollToBottom();
        this.$refs.globalChatBoxInner.$refs.messageToSendInput?.focus();
      }
        , 200)

    },



    closeMenuIfMobile() {

      if ($globals.screenIsMobile())
        this.hideSidebar = true;
    },


    showGlobalPdfIframeModal(src, title) {
      this.globalPdfIframeModalSrc = src;
      this.globalPdfIframeModalTitle = title;
      this.$refs.globalPdfIframeModal.show();
    },


    openTargetForNewEntityModal(entity, extraParams) {
      this.chosenEntityToCreateInPopup = entity;
      this.chosenEntityToCreateInPopup_extraParams = extraParams;
      this.$refs.targetForNewEntityModal.show();
    },

    findCustomer(callback, ...args) {

      // we call the callback with a new field on the customer - chosenEvent
      this.$refs.findCustomerModal.show((customer) => { callback(customer, ...args) });
    },


    goBack() {
      if (window.routerLastFrom == 'login') {
        this.$router.push('/');
      } else {
        this.$router.go(-1);
      }
    },

    // this closes open menus if clicked outside of them.
    // currently it is by id but can be changed to class if we have multiple menus
    setInteraction(clickEvent) {


      let isMenuOpenerButton;


      let element = clickEvent.target;




      while (element) {

        if (element.getAttribute('class') && element.getAttribute('class').includes('popupMenuToHideOrNot_openerButton'))

          isMenuOpenerButton = true;

        element = element.parentElement;

      }

      if (!isMenuOpenerButton) {

        document.querySelectorAll('.popupMenuToHideOrNot').forEach(e => e.classList.remove('popupMenuToHideOrNot_showMenu'))

      }


    },

  },

  mounted() {

    

    this.hideSidebar = this.$globals.screenIsMobile;

    this.$root.$on('findCustomer', this.findCustomer);
    this.$root.$on('openTaskModal', (task) => this.$refs.taskModal.show(task))  //arrow function because taskmodal is not loaded if not logged in
    this.$root.$on('showGlobalPdfIframeModal', this.showGlobalPdfIframeModal);
    this.$root.$on('globalChatBox', this.openGlobalChatBox);
    this.$root.$on('showQuickCalendar', this.showQuickCalendar);
    this.$root.$on('hideQuickCalendar', this.$refs.quickCalendar.hide);
    this.$root.$on('openTargetForNewEntityModal', this.openTargetForNewEntityModal);
    this.$root.$on('showSpinner', () => this.isLoading = true);
    this.$root.$on('hideSpinner', () => this.isLoading = false);
    this.$root.$on('showCommentModal', this.$refs.commentModal.show);
    this.$root.$on('showSummonModal', this.$refs.summonModal.show);


    //when close global chat box modal remove the active chat
    let that = this;
    $('.globalChatBoxModal').on('hidden.bs.modal', function () {
      that.chatMsgGroup = '';
      
    })




    // apiRequest error alerts
    document.addEventListener('s_apiError', (customEvent) => {
      const errorData = customEvent.detail

      //hide spinner in case it was shown
      this.isLoading = false;

      if (customEvent?.detail?.message != 'Internal server error') {
        // alert(customEvent.detail.message);
        // return;

        this.$swal({
          title: customEvent.detail.message,
          type: 'warning',
          confirmButtonText: 'המשך',
        });

        return;
      }



      this.$notify({
        type: 'error',
        title: 'שגיאה בהעברת הנתונים',
        // text: errorData?.message || this.$t('error'),
        text: 'התרחשה שגיאה בעת הפנייה לשרת.',
        duration: 2500
      })
    }, false);

    // apiRequest success alerts
    document.addEventListener('s_apiSuccess', (customEvent) => {
      const code = customEvent.detail


      //hide spinner in case it was shown
      this.isLoading = false;
      if (code == 'resource_created') {
        this.$notify({
          type: 'success',
          title: this.$t('created_successfully'),
          text: 'הפעולה התבצעה בהצלחה',
          // duration: 50000
          //text: errorData?.message || this.$t('error')
        })
      }
    }, false)


  },
  components: {
    ContactModal,
    SideMenu,
    HeaderNav,
    Login,
    FindCustomerModal,
    TaskModal,
    PlanupCalendar,
    PlanupMultiMessagesChat,
    CommentModal,
    QuickMeetingModal,
    SummonModal
  },
}

</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

.slide-fade-enter-active {
  transition: all .2s ease;
}

.slide-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}

.kioskView {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  position: fixed !important;
  width: 100% !important;
}
</style>







<style>
/* global chat */

.globalChatBoxModal>*>*>.modal-body {
  /* background-image: url(https://planup-il.s3.eu-central-1.amazonaws.com/chatbg.png) !important; */
  background-image: url(https://planup-il.s3.eu-central-1.amazonaws.com/whatsappbg_new.png) !important;
  background-color: #e5ddd5 !important;
  border-radius: 8px;
  overflow: hidden;
}


.globalChatBoxModal .chat-header {
  border-bottom: solid 1px #d1d1d1;
}


.globalChatBoxModal .custom-scrollbar {
  background: rgba(229, 221, 213, 0.6) !important;
}

.globalChatBoxModal .chat-header {
  /* background: #128C7E !important; */
  background: white !important;
  border-bottom: solid 1px #ddd
}

.globalChatBoxModal .modal-header {
  /* background: #128C7E !important; */
  /* height: 0 !important; */
  /* padding-top: 10px !important; */
}


.globalChatBoxModal .chat-input-wrapper {
  /* background: #f0f2f5 !important; */
  background: white !important;
  /* border-top: none !important; */
  border-top: solid 1px #d1d1d1;
}

.globalChatBoxModal .chat-input-inner-wrapper {
  border: solid 1px #d1d1d1 !important;
}

/* 




.globalChatBoxModal .chat-header img {
  border: none !important;
}

.globalChatBoxModal .chatname {
  color: white !important;
}

.globalChatBoxModal .chat-input-wrapper {
  background: none !important;
  border-top: none !important;
}

.globalChatBoxModal .btn-close {
  filter: invert(1) !important;
  z-index: 1 !important;
  position: relative !important;
  top: 10px !important;
}


.globalChatBoxModal .modal-content {
  overflow: hidden !important;
}

.globalChatBoxModal .chat-header .whatsapp::after,
.globalChatBoxModal .chat-header .email::after {
  display: none !important;
}

.chat-input-wrapper button.btn.btn-primary.btn.btn {
  background: #128C7E !important;
  border-color: #128C7E !important;
}

.chat-input-wrapper div {
  border-color: #128C7E !important;
  background: white;
} */
</style>